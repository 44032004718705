* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ant-layout-header {
  background-color: #0090ff;
}

.ant-form-item-explain-error::first-letter {
  text-transform: capitalize !important;
}
