$spaces: auto, 0, 4, 6, 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 64, 80, 112, 128,
  160;
@each $space in $spaces {
  .ml-#{$space} {
    margin-left: $space + px !important;
  }
  .mr-#{$space} {
    margin-right: $space + px !important;
  }
  .mt-#{$space} {
    margin-top: $space + px !important;
  }
  .mb-#{$space} {
    margin-bottom: $space + px !important;
  }
  .mx-#{$space} {
    margin: 0 $space + px !important;
  }
  .ml-n#{$space} {
    margin-left: -$space + px !important;
  }
  .mr-n#{$space} {
    margin-right: -$space + px !important;
  }
  .m-#{$space} {
    margin: $space !important;
  }
  .pl-#{$space} {
    padding-left: $space + px !important;
  }
  .pr-#{$space} {
    padding-right: $space + px !important;
  }
  .pt-#{$space} {
    padding-top: $space + px !important;
  }
  .pb-#{$space} {
    padding-bottom: $space + px !important;
  }
  .p-#{$space} {
    padding: $space + px !important;
  }
  .w-#{$space} {
    width: $space + px !important;
  }
  .gap-#{$space} {
    gap: $space + px !important;
  }
}

$radiusValues: 2, 4, 6, 8;
@each $radiusValue in $radiusValues {
  .radius-#{$radiusValue} {
    border-radius: $radiusValue + px !important;
  }
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.col {
  &-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  &-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  &-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  &-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  &-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  &-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  &-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  &-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  &-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  &-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

.ai {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.jc {
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }
}

.al-self {
  &-start {
    align-self: flex-start;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: flex-end;
  }
}

.flex-column {
  flex-direction: column;
}

.full-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.relative {
  position: relative;
}

.top-30 {
  top: 30% !important;
}

.start-45 {
  left: 45% !important;
}

.w {
  &-100 {
    width: 100% !important;
  }

  &-auto {
    width: auto !important;
  }
}

.min-vh {
  &-100 {
    min-height: 100vh !important;
  }

  &-35 {
    min-height: 35vh !important;
  }
}

.lh-default {
  line-height: 1 !important;
}

.pointer {
  cursor: pointer;
}
